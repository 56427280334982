"use strict";

// Class definition
var KTChartsWidget31 = (function () {
	// Private methods
	var initChart1 = function () {
		// Check if amchart library is included
		if (typeof am5 === "undefined") {
			return;
		}

		var element = document.getElementById("kt_charts_widget_31_chart");

		if (!element) {
			return;
		}

		var chart;
		var root;

		var init = function() {
			// Create root element
			// https://www.amcharts.com/docs/v5/getting-started/#Root_element
			root = am5.Root.new(element);

			// Set themes
			// https://www.amcharts.com/docs/v5/concepts/themes/
			root.setThemes([am5themes_Animated.new(root)]);

			// Create chart
			// https://www.amcharts.com/docs/v5/charts/radar-chart/
			chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					panX: false,
					panY: false,
					wheelX: "panX",
					wheelY: "zoomX",
					innerRadius: am5.percent(40),
					radius: am5.percent(70),
					arrangeTooltips: false,
				})
			);

			var cursor = chart.set(
				"cursor",
				am5radar.RadarCursor.new(root, {
					behavior: "zoomX",
				})
			);

			cursor.lineY.set("visible", false);

			// Create axes and their renderers
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
			var xRenderer = am5radar.AxisRendererCircular.new(root, {
				minGridDistance: 30,
			});
			
			xRenderer.labels.template.setAll({
				textType: "radial",
				radius: 10,
				paddingTop: 0,
				paddingBottom: 0,
				centerY: am5.p50,
				fontWeight: "300",
				fontSize: 9,
				fill: am5.color(KTUtil.getCssVariableValue("--kt-gray-800")),
			});

			xRenderer.grid.template.setAll({
				location: 0.5,
				strokeDasharray: [2, 2],
				stroke: KTUtil.getCssVariableValue('--kt-gray-400')
			});

			var xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					maxDeviation: 0,
					categoryField: "name",
					renderer: xRenderer,
				})
			);

			var yRenderer = am5radar.AxisRendererRadial.new(root, {
				minGridDistance: 30,
			});

			yRenderer.labels.template.setAll({
				fontWeight: "500",
				fontSize: 12,
				fill: am5.color(KTUtil.getCssVariableValue("--kt-gray-700")),
			});

			var yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: yRenderer,
				})
			);

			yRenderer.grid.template.setAll({
				strokeDasharray: [2, 2],
				stroke: KTUtil.getCssVariableValue('--kt-gray-400')				
			});

			// Create series
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
			var series1 = chart.series.push(
				am5radar.RadarLineSeries.new(root, {
					name: "Score",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "value1",
					categoryXField: "name",
					tooltip: am5.Tooltip.new(root, {
						labelText: "Score: {value1}"
					}),
					fill: am5.color(KTUtil.getCssVariableValue("--kt-primary")),
				})
			);

			series1.strokes.template.setAll({
				strokeOpacity: 0,
			});

			series1.fills.template.setAll({
				visible: true,
				fill: am5.color(KTUtil.getCssVariableValue("--kt-primary")),
				fillOpacity: 0.5,
			});

			

			var legend = chart.radarContainer.children.push(
				am5.Legend.new(root, {
					width: 150,
					centerX: am5.p50,
					centerY: am5.p50
				})
			);
			legend.data.setAll([series1]);

			legend.labels.template.setAll({
				fontWeight: "600",
				fontSize: 13,
				fill: am5.color(KTUtil.getCssVariableValue("--kt-gray-700")),
			});

			// Set data
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
			var data = [
				{
					name: "Representation",
					value1: 160.2,
				},
				{
					name: "Initiation\nof Structure",
					value1: 120.1,
				},
				{
					name: "Predictive\nAccuracy",
					value1: 150.7,
				},
				{
					name: "Tolerance\nof Uncertainty",
					value1: 69.4,
				},
				{
					name: "Persuasivness",
					value1: 78.5,
				},
				{
					name: "Tollerance\nand Freedom",
					value1: 77.6,
				},
				{
					name: "Role\nAssumption",
					value1: 69.8,
				},
				{
					name: "Consideration",
					value1: 63.6,
				},
				{
					name: "Prodiction\nEmphasis",
					value1: 59.7,
				},
				{
					name: "Integration",
					value1: 64.3,
				},
				{
					name: "Superior\nOrientation",
					value1: 52.9,
				},
				{
					name: "Demand\nReconciliation",
					value1: 42.9,
				},
			];

			series1.data.setAll(data);
			xAxis.data.setAll(data);

			// Animate chart and series in
			// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
			series1.appear(1000);
			chart.appear(1000, 100);
		}

		// On amchart ready
		am5.ready(function () {
			init();
		}); // end am5.ready()

		// Update chart on theme mode change
		KTThemeMode.on("kt.thememode.change", function() {     
			// Destroy chart
			root.dispose();

			// Reinit chart
			init();
		});
	};

	// Public methods
	return {
		init: function () {
			initChart1();
		}
	};
})();

// Webpack support
if (typeof module !== "undefined") {
	module.exports = KTChartsWidget31;
}

// On document ready
KTUtil.onDOMContentLoaded(function () {
	KTChartsWidget31.init();
});
